var render = function render(_c,_vm){return _c('div',{staticClass:"grid-view__cell",class:{
    ...(_vm.data.staticClass && {
      [_vm.data.staticClass]: true,
    }),
    'cell-error': _vm.props.value === 'NaN',
  }},[_c('div',{staticClass:"grid-field-number"},[_vm._v("\n    "+_vm._s(_vm.props.value === 'NaN'
        ? _vm.parent.$t('fieldErrors.invalidNumber')
        : _vm.$options.methods.formatFrontendNumber(_vm.props.field, _vm.props.value))+"\n  ")])])
}
var staticRenderFns = []

export { render, staticRenderFns }