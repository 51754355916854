var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"sidebar__section sidebar__section--scrollable"},[(_vm.hasItems)?_c('div',{staticClass:"sidebar__section-scrollable"},[_c('div',{staticClass:"sidebar__section-scrollable-inner",attrs:{"data-highlight":"applications"}},[(_vm.pendingJobs[null].length)?_c('ul',{staticClass:"tree"},_vm._l((_vm.pendingJobs[null]),function(job){return _c(_vm.getPendingJobComponent(job),{key:job.id,tag:"component",attrs:{"job":job}})}),1):_vm._e(),_vm._v(" "),(_vm.applicationsCount)?_c('ul',{staticClass:"tree"},_vm._l((_vm.groupedApplicationsForSelectedWorkspace),function(applicationGroup){return _c('div',{key:applicationGroup.type},[(applicationGroup.applications.length > 0)?[_c('div',{staticClass:"tree__heading"},[_vm._v("\n              "+_vm._s(applicationGroup.name)+"\n            ")]),_vm._v(" "),_c('ul',{staticClass:"tree",class:{
                'margin-bottom-0': _vm.pendingJobs[applicationGroup.type].length,
              },attrs:{"data-highlight":"applications"}},_vm._l((applicationGroup.applications),function(application){return _c(_vm.getApplicationComponent(application),{directives:[{name:"sortable",rawName:"v-sortable",value:({
                  id: application.id,
                  update: _vm.orderApplications,
                  handle: '[data-sortable-handle]',
                  marginTop: -1.5,
                  enabled: _vm.$hasPermission(
                    'workspace.order_applications',
                    _vm.selectedWorkspace,
                    _vm.selectedWorkspace.id
                  ),
                }),expression:"{\n                  id: application.id,\n                  update: orderApplications,\n                  handle: '[data-sortable-handle]',\n                  marginTop: -1.5,\n                  enabled: $hasPermission(\n                    'workspace.order_applications',\n                    selectedWorkspace,\n                    selectedWorkspace.id\n                  ),\n                }"}],key:application.id,tag:"component",attrs:{"application":application,"pending-jobs":_vm.pendingJobs[application.type],"workspace":_vm.selectedWorkspace}})}),1),_vm._v(" "),(_vm.pendingJobs[applicationGroup.type].length)?_c('ul',{staticClass:"tree"},_vm._l((_vm.pendingJobs[applicationGroup.type]),function(job){return _c(_vm.getPendingJobComponent(job),{key:job.id,tag:"component",attrs:{"job":job}})}),1):_vm._e()]:_vm._e()],2)}),0):_vm._e()])]):_vm._e(),_vm._v(" "),(
      _vm.$hasPermission(
        'workspace.create_application',
        _vm.selectedWorkspace,
        _vm.selectedWorkspace.id
      )
    )?_c('div',{staticClass:"sidebar__new-wrapper",class:{
      'sidebar__new-wrapper--separator': _vm.hasItems,
    }},[_c('a',{ref:"createApplicationContextLink",staticClass:"sidebar__new",on:{"click":function($event){return _vm.$refs.createApplicationContext.toggle(
          _vm.$refs.createApplicationContextLink
        )}}},[_c('i',{staticClass:"sidebar__new-icon iconoir-plus"}),_vm._v("\n      "+_vm._s(_vm.$t('action.createNew'))+"...\n    ")])]):_vm._e(),_vm._v(" "),_c('CreateApplicationContext',{ref:"createApplicationContext",attrs:{"workspace":_vm.selectedWorkspace}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }