var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.visible)?_c('li',{staticClass:"select__item select__item--no-options",class:{
    hidden: !_vm.isVisible(_vm.query),
    visible: _vm.isVisible(_vm.query),
    active: _vm.isActive(_vm.value),
    disabled: _vm.disabled,
    hover: _vm.isHovering(_vm.value),
  },on:{"click":function($event){return _vm.$emit('click', $event)}}},[_c('a',{staticClass:"select__item-link",class:{ 'select__item-link--indented': _vm.indented },on:{"click":function($event){return _vm.select(_vm.value, _vm.disabled)},"mousemove":function($event){return _vm.hover(_vm.value, _vm.disabled)}}},[_c('div',{staticClass:"select__item-name"},[(_vm.multiple.value)?_c('div',[_c('Checkbox',{attrs:{"disabled":_vm.disabled,"checked":_vm.isActive(_vm.value)}})],1):_vm._e(),_vm._v(" "),_vm._t("default",function(){return [(_vm.icon)?_c('i',{directives:[{name:"tooltip",rawName:"v-tooltip",value:(_vm.iconTooltip),expression:"iconTooltip"}],staticClass:"select__item-icon",class:_vm.icon}):_vm._e(),_vm._v(" "),(_vm.image)?_c('img',{staticClass:"select__item-image",attrs:{"src":_vm.image}}):_vm._e(),_vm._v(" "),_c('span',{staticClass:"select__item-name-text",attrs:{"title":_vm.name}},[_vm._v(_vm._s(_vm.name))])]})],2),_vm._v(" "),(_vm.description !== null)?_c('div',{staticClass:"select__item-description"},[_vm._v("\n      "+_vm._s(_vm.description)+"\n    ")]):_vm._e()]),_vm._v(" "),(!_vm.multiple.value)?_c('i',{staticClass:"select__item-active-icon iconoir-check"}):_vm._e()]):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }