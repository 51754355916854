var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"dropdown",class:{
    'dropdown--floating': !_vm.showInput,
    'dropdown--disabled': _vm.disabled,
    'dropdown--large': _vm.size === 'large',
    'dropdown--error': _vm.error,
  },attrs:{"tabindex":_vm.realTabindex},on:{"contextmenu":function($event){$event.stopPropagation();},"focusin":function($event){return _vm.show()},"focusout":function($event){return _vm.focusout($event)}}},[(_vm.showInput)?_c('a',{staticClass:"select-options__dropdown-selected dropdown__selected",on:{"click":function($event){return _vm.show()}}},[(_vm.initialDisplayName)?_c('div',[_vm._v("\n      "+_vm._s(_vm.initialDisplayName)+"\n    ")]):(_vm.hasValue())?_c('div',[_vm._v("\n      "+_vm._s(_vm.selectedName)+"\n    ")]):_vm._e(),_vm._v(" "),(!_vm.disabled)?_c('i',{staticClass:"dropdown__toggle-icon iconoir-nav-arrow-down"}):_vm._e()]):_vm._e(),_vm._v(" "),_c('div',{ref:"itemsContainer",staticClass:"dropdown__items",class:{
      hidden: !_vm.open,
      'dropdown__items--fixed': _vm.fixedItemsImmutable,
    }},[(_vm.showSearch)?_c('div',{staticClass:"select__search"},[_c('i',{staticClass:"select__search-icon iconoir-search"}),_vm._v(" "),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.query),expression:"query"}],ref:"search",staticClass:"select__search-input",attrs:{"type":"text","placeholder":_vm.searchText,"tabindex":"0"},domProps:{"value":(_vm.query)},on:{"keyup":_vm.search,"input":function($event){if($event.target.composing)return;_vm.query=$event.target.value}}})]):_vm._e(),_vm._v(" "),_c('ul',{directives:[{name:"prevent-parent-scroll",rawName:"v-prevent-parent-scroll"},{name:"auto-overflow-scroll",rawName:"v-auto-overflow-scroll"}],ref:"items",staticClass:"select__items",class:{ 'select__items--no-max-height': _vm.fixedItemsImmutable },attrs:{"tabindex":"-1"},on:{"scroll":_vm.scroll}},[(_vm.showEmptyValue)?_c('FieldCollaboratorDropdownItem',{attrs:{"name":'',"value":null,"color":''}}):_vm._e(),_vm._v(" "),_vm._l((_vm.results),function(collaborator){return _c('FieldCollaboratorDropdownItem',{key:collaborator.user_id,attrs:{"name":collaborator.name,"value":collaborator.user_id}})})],2),_vm._v(" "),(_vm.isNotFound)?_c('div',{staticClass:"select__description"},[_vm._v("\n      "+_vm._s(_vm.$t('fieldmultipleCollaboratorsDropdown.notFound'))+"\n    ")]):_vm._e()])])
}
var staticRenderFns = []

export { render, staticRenderFns }