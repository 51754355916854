var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('ThemeConfigBlockSection',{scopedSlots:_vm._u([{key:"default",fn:function(){return [_c('FormGroup',{staticClass:"margin-bottom-2",attrs:{"horizontal-narrow":"","small-label":"","required":"","label":_vm.$t('imageThemeConfigBlock.alignment')},scopedSlots:_vm._u([{key:"after-input",fn:function(){return [_c('ResetButton',{attrs:{"default-value":_vm.theme?.image_alignment},model:{value:(_vm.values.image_alignment),callback:function ($$v) {_vm.$set(_vm.values, "image_alignment", $$v)},expression:"values.image_alignment"}})]},proxy:true}])},[_c('HorizontalAlignmentsSelector',{model:{value:(_vm.values.image_alignment),callback:function ($$v) {_vm.$set(_vm.values, "image_alignment", $$v)},expression:"values.image_alignment"}})],1),_vm._v(" "),_c('FormGroup',{staticClass:"margin-bottom-2",attrs:{"horizontal-narrow":"","label":_vm.$t('imageThemeConfigBlock.maxWidthLabel'),"small-label":"","required":"","error-message":_vm.$v.values.image_max_width.$dirty &&
          !_vm.$v.values.image_max_width.integer
            ? _vm.$t('error.integerField')
            : !_vm.$v.values.image_max_width.minValue
            ? _vm.$t('error.minValueField', { min: 0 })
            : !_vm.$v.values.image_max_width.maxValue
            ? _vm.$t('error.maxValueField', { max: 100 })
            : !_vm.$v.values.image_max_width.required
            ? _vm.$t('error.requiredField')
            : ''},scopedSlots:_vm._u([{key:"after-input",fn:function(){return [_c('ResetButton',{attrs:{"default-value":_vm.theme?.image_max_width},model:{value:(_vm.values.image_max_width),callback:function ($$v) {_vm.$set(_vm.values, "image_max_width", $$v)},expression:"values.image_max_width"}})]},proxy:true}])},[_c('FormInput',{attrs:{"default-value-when-empty":_vm.defaultValuesWhenEmpty[`image_min_width`],"type":"number","min":0,"max":100,"remove-number-input-controls":"","placeholder":_vm.$t('imageThemeConfigBlock.maxWidthPlaceholder'),"to-value":(value) => (value ? parseInt(value) : null)},scopedSlots:_vm._u([{key:"suffix",fn:function(){return [_c('i',{staticClass:"iconoir-percentage"})]},proxy:true}]),model:{value:(_vm.values.image_max_width),callback:function ($$v) {_vm.$set(_vm.values, "image_max_width", $$v)},expression:"values.image_max_width"}})],1),_vm._v(" "),_c('FormGroup',{staticClass:"margin-bottom-2",attrs:{"horizontal-narrow":"","small-label":"","required":"","label":_vm.$t('imageThemeConfigBlock.maxHeightLabel'),"error-message":_vm.$v.values.image_max_height.$dirty &&
          !_vm.$v.values.image_max_height.integer
            ? _vm.$t('error.integerField')
            : !_vm.$v.values.image_max_height.minValue
            ? _vm.$t('error.minValueField', { min: 5 })
            : !_vm.$v.values.image_max_height.maxValue
            ? _vm.$t('error.maxValueField', { max: 3000 })
            : ''},scopedSlots:_vm._u([{key:"after-input",fn:function(){return [_c('ResetButton',{attrs:{"default-value":_vm.theme?.image_max_height},model:{value:(_vm.imageMaxHeight),callback:function ($$v) {_vm.imageMaxHeight=$$v},expression:"imageMaxHeight"}})]},proxy:true}])},[_c('FormInput',{attrs:{"default-value-when-empty":_vm.defaultValuesWhenEmpty[`image_min_height`],"type":"number","remove-number-input-controls":"","placeholder":_vm.$t('imageThemeConfigBlock.maxHeightPlaceholder'),"to-value":(value) => (value ? parseInt(value) : null)},scopedSlots:_vm._u([{key:"suffix",fn:function(){return [_vm._v("px")]},proxy:true}]),model:{value:(_vm.imageMaxHeight),callback:function ($$v) {_vm.imageMaxHeight=$$v},expression:"imageMaxHeight"}})],1),_vm._v(" "),_c('FormGroup',{staticClass:"margin-bottom-2",attrs:{"horizontal-narrow":"","small-label":"","required":"","label":_vm.$t('imageThemeConfigBlock.imageConstraintsLabel')},scopedSlots:_vm._u([{key:"after-input",fn:function(){return [_c('ResetButton',{attrs:{"default-value":_vm.theme?.image_constraint},model:{value:(_vm.imageConstraintForReset),callback:function ($$v) {_vm.imageConstraintForReset=$$v},expression:"imageConstraintForReset"}})]},proxy:true}])},[_c('Dropdown',{staticClass:"flex-grow-1",attrs:{"fixed-items":"","show-search":true},model:{value:(_vm.values.image_constraint),callback:function ($$v) {_vm.$set(_vm.values, "image_constraint", $$v)},expression:"values.image_constraint"}},_vm._l((_vm.imageConstraintChoices),function({ name, label }){return _c('DropdownItem',{key:name,attrs:{"disabled":_vm.constraintDisabled(name),"description":_vm.constraintDisabled(name)
                ? _vm.$t(`imageThemeConfigBlock.imageConstraint${label}Disabled`)
                : '',"name":label,"value":name}})}),1)],1),_vm._v(" "),_c('FormGroup',{staticClass:"margin-bottom-2",attrs:{"horizontal-narrow":"","small-label":"","required":"","label":_vm.$t('imageThemeConfigBlock.imageBorderRadiusLabel'),"error-message":_vm.$v.values.image_border_radius.$dirty &&
          !_vm.$v.values.image_border_radius.integer
            ? _vm.$t('error.integerField')
            : !_vm.$v.values.image_border_radius.minValue
            ? _vm.$t('error.minValueField', { min: 0 })
            : !_vm.$v.values.image_border_radius.maxValue
            ? _vm.$t('error.maxValueField', { max: 100 })
            : ''},scopedSlots:_vm._u([{key:"after-input",fn:function(){return [_c('ResetButton',{attrs:{"default-value":_vm.theme?.image_border_radius},model:{value:(_vm.values.image_border_radius),callback:function ($$v) {_vm.$set(_vm.values, "image_border_radius", $$v)},expression:"values.image_border_radius"}})]},proxy:true}])},[_c('FormInput',{attrs:{"default-value-when-empty":_vm.defaultValuesWhenEmpty[`image_border_radius`],"type":"number","min":0,"max":100,"remove-number-input-controls":"","placeholder":_vm.$t('imageThemeConfigBlock.imageBorderRadiusPlaceholder'),"to-value":(value) => (value ? parseInt(value) : null)},scopedSlots:_vm._u([{key:"suffix",fn:function(){return [_vm._v("px")]},proxy:true}]),model:{value:(_vm.values.image_border_radius),callback:function ($$v) {_vm.$set(_vm.values, "image_border_radius", $$v)},expression:"values.image_border_radius"}})],1)]},proxy:true},{key:"preview",fn:function(){return [_c('ABImage',{attrs:{"src":"/img/favicon_192.png"}})]},proxy:true}])})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }