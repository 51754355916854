var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"preview-navigation-bar"},[_c('div',{staticClass:"preview-navigation-bar__user-selector"},[_c('UserSelector')],1),_vm._v(" "),_c('div',{staticClass:"preview-navigation-bar__address-bar"},[_vm._l((_vm.splitPath),function(pathPart){return [(pathPart.type === 'variable')?_c('PreviewNavigationBarInput',{key:pathPart.key,class:`preview-navigation-bar__parameter-input--${
          _vm.paramTypeMap[pathPart.value]
        }`,attrs:{"validation-fn":pathPart.validationFn,"default-value":_vm.pageParameters[pathPart.value]},on:{"change":function($event){return _vm.actionSetParameter({
            page: _vm.page,
            name: pathPart.value,
            value: $event,
          })}}}):_c('div',{key:`else_${pathPart.key}`,staticClass:"preview-navigation-bar__address-bar-path"},[_vm._v("\n        "+_vm._s(pathPart.value)+"\n      ")])]}),_vm._v(" "),_vm._l((_vm.queryParams),function(queryParam,index){return [_c('span',{key:`separator-${queryParam.key}`,staticClass:"preview-navigation-bar__query-separator"},[_vm._v("\n        "+_vm._s(index === 0 ? '?' : '&')+"\n      ")]),_vm._v(" "),_c('PreviewNavigationBarQueryParam',{key:`param-${queryParam.key}`,class:`preview-navigation-bar__query-parameter-input--${queryParam.type}`,attrs:{"validation-fn":queryParam.validationFn,"default-value":_vm.pageParameters[queryParam.name],"name":queryParam.name},on:{"change":function($event){return _vm.actionSetParameter({
            page: _vm.page,
            name: queryParam.name,
            value: $event,
          })}}})]})],2),_vm._v(" "),_c('div')])
}
var staticRenderFns = []

export { render, staticRenderFns }