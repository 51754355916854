const EnterpriseFeatures = {
  RBAC: 'RBAC',
  SSO: 'SSO',
  TEAMS: 'TEAMS',
  SUPPORT: 'SUPPORT',
  AUDIT_LOG: 'AUDIT_LOG',
  ENTERPRISE_SETTINGS: 'ENTERPRISE_SETTINGS',
  DATA_SYNC: 'DATA_SYNC',
  CHART_WIDGET: 'CHART_WIDGET',
}

export default EnterpriseFeatures
