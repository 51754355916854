var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"tabs",class:{
    'tabs--full-height': _vm.fullHeight,
    'tabs--large-offset': _vm.largeOffset,
    'tabs--nopadding': _vm.noPadding,
    'tabs--grow-items': _vm.growItems,
  }},[_c('ul',{staticClass:"tabs__header"},_vm._l((_vm.tabs),function(tab,index){return _c('li',{directives:[{name:"tooltip",rawName:"v-tooltip",value:(tab.tooltip),expression:"tab.tooltip"}],key:`${tab.title} ${tab.tooltip}`,staticClass:"tabs__item",class:{
        'tabs__item--active': _vm.isActive(index),
        'tabs__item--disabled': tab.disabled,
      },on:{"click":function($event){tab.disabled ? _vm.$emit('click-disabled', index) : _vm.selectTab(index)}}},[_c('a',{staticClass:"tabs__link",class:{ 'tabs__link--disabled': tab.disabled },attrs:{"href":_vm.getHref(index)},on:{"click":function($event){$event.preventDefault();}}},[(tab.icon)?_c('i',{class:tab.icon}):_vm._e(),_vm._v("\n        "+_vm._s(tab.title)+"\n        "),(tab.appendIcon)?_c('i',{class:tab.appendIcon}):_vm._e()])])}),0),_vm._v(" "),_vm._t("default")],2)
}
var staticRenderFns = []

export { render, staticRenderFns }